<template>
    <div>
        <v-container class="text-center pa-8 mt-1">
            <img src="../assets/logo.png" style="width:255px;margin-top:60px;"/>
            <h2 class="primary--text mt-3">Pavicon Enterprise Resource Planner</h2>
            <h4 class="primary--text mt-3">Login</h4>

            <v-card
            style="background-color: #f2f2f2; border-color: #f2f2f2;"
            outlined 
            class="mt-5 pa-4">
                <v-text-field
                    label="Email"
                    :rules="rules"
                    hide-details="auto"
                    v-model="email"
                    outlined
                ></v-text-field>
                <v-text-field 
                    label="Password" 
                    :rules ="rules"
                    v-model="password"
                    outlined
                    class="pt-3"
                    type="password"
                ></v-text-field>
                <v-btn style="width: 100%; background-color: #ff8800; text-transform: capitalize;font-size: 19px; margin-top: -20px;" @click="loginSubmit" class="primary--text">Login</v-btn>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    name: "Login",
    data: () => ({
        email: "",
        password: "",
        rules: [
            value => !!value || 'Required.',
            value => (value && value.length >= 4) || 'Min 4 characters',
      ]
    }),
    methods:{
        async loginSubmit () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    email: this.email,
                    password: this.password
                })
            };
            const res = await fetch('https://paviconhse.ubuniworks.com/api/v1/auth/login', requestOptions)
            const data = await res.json()
            if(data.status == true){
                localStorage.setItem('user', JSON.stringify(data.user))
                localStorage.setItem('userDate',Date.now())
                localStorage.setItem("timesheet", data.user.timesheet)
                localStorage.setItem("timesheetState", data.user.state)
                localStorage.setItem("timesheetStateData", data.user.state_data)
                Swal.fire({
                    icon: 'success',
                    title: 'User successfully logged in'
                })
                location.reload()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: data.message
                })
            }
        }
    },
    mounted(){
        let user = JSON.parse(localStorage.getItem('user'))
        if(user != null){
            this.$router.push('/home')
        }
    }
}
</script>

<style scoped>

</style>