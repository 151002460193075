<template>
<div>
  <v-app v-if="user != null">
    <v-app-bar
      app
      color="primary"
      dark style="background: #fff !important;"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="140"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/logo.png"
          width="135"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn text style="padding:0px !important;">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="padding:0px !important;background-color: #fff;"
              color="primary--text"
              elevation="0"
              v-bind="attrs"
              v-on="on">
              <span style="width: 115px;overflow: hidden;text-transform:capitalize;">{{ user.name }}</span> <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list  style="background:#ff8800;">
            <v-list-item style="background: #ff8800; margin:5px 10px; border-radius:10px;">
              <v-list-item-title @click="profileClick()" style="color:#002060;">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item style="background: #ff8800; margin:5px 10px; border-radius:10px;">
              <v-list-item-title @click="logoutClick()" style="color:#002060;">Log Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="padding:0px !important;background-color: #fff;"
              color="primary--text"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
            <v-badge
              :value="notifications.length"
              color="red"
              overlap
            ><v-icon style="color: #ff8800;">mdi-bell</v-icon> </v-badge>
            </v-btn>
          </template>
          <v-list  style="background:#ff8800;">
            <div v-if="notifications.length > 0">
            <v-list-item
            v-for="(notification, index) in notifications"
            :key="index">
              <v-list-item-title style="color:#002060;">- {{ notification.notification }}</v-list-item-title>
            </v-list-item>
            </div>
            <div v-else>
              <v-list-item-title style="color:#c53535;">No notifications found</v-list-item-title>
            </div>
          </v-list>
        </v-menu>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
      
    <v-btn
      @click="clockCheck()"
      style="z-index:5; right:0; bottom: 10px;position: absolute;"
      class="mr-2"
      dark
      color="#ff8800"><v-icon> mdi-clock-time-eight</v-icon> {{ clockState }}</v-btn>
    </v-main>

    <v-btn
    color="primary"
    elevation="2"
    fab
    :style="{display:installBtn }"
    ><v-icon>fas fa-lock</v-icon></v-btn>

    <bottom-nav />
  </v-app>

  <!-- Login screen -->
  <v-app v-else>
    <v-main>
      <login />
    </v-main>
    
    <v-btn color="primary" elevation="2" fab :style="{display:installBtn }" style="margin-left:10px;margin-bottom:10px;"><v-icon>mdi-tray-arrow-down</v-icon></v-btn>
    
  </v-app>
</div>
</template>

<style>
.v-main__wrap{
  background: #f2f2f2;
}
</style>

<script>
import Swal from 'sweetalert2'
import BottomNav from './components/BottomNavigation.vue'
import Login from './components/Login.vue'
import axios from 'axios'
import { mapGetters,mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    BottomNav,
    Login
  },
  data: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    userDate: JSON.parse(localStorage.getItem('userDate')),
    installBtn: "none",
    installer: undefined,
    clockState: "Clock In",
    location_pos:[]
  }),
  computed: {
      ... mapGetters ( {notifications : "getNotifications"})
  },
  methods:{
    ... mapActions (['fetchNotifications']),
    profileClick() {
      this.$router.push('/profile')
    },
    logoutClick() {
      localStorage.removeItem("user")
      localStorage.removeItem("userDate")
      localStorage.removeItem("timesheet")
      localStorage.removeItem("timesheetState")
      localStorage.removeItem("timesheetStateData")
      Swal.fire({
          icon: 'success',
          title: 'User successfully logged out'
      })
      this.$router.push('/')
      location.reload()
    },
    clockCheck() {
      if(!("geolocation" in navigator)) {
        Swal.fire({
            icon: 'error',
            title: 'Location is not available.'
        })
        return;
      }
      navigator.geolocation.getCurrentPosition(pos => {
        this.location_pos = pos;
      }, err => {
          console.log(err);
        Swal.fire({
            icon: 'error',
            title: 'Location is not available.'
        })
      })

      if (localStorage.getItem("timesheetStateData") == "clockin") {
        Swal.fire({
          title: "Clock Out",
          text: "Are you sure you want to clock Out?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Clock Out!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.clockOut()
          }
        })  
      } else if (localStorage.getItem("timesheetStateData") == "clockout") { 
        Swal.fire({
          title: "Clock In Overtime",
          text: "Are you sure you want to clock In Overtime?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Clock In!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.clockInOT()
          }
        })
      } else if (localStorage.getItem("timesheetStateData") == "clockinot") { 
        Swal.fire({
          title: "Clock Out Overtime",
          text: "Are you sure you want to clock Out Overtime?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Clock Out!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.clockOutOT()
          }
        })
      } else {
        Swal.fire({
          title: "Clock In",
          text: "Are you sure you want to clock In?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Clock In!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.clockIn()
          }
        })
      }
    },
    clockIn() {
      let formData = new FormData();
      formData.append("type", this.user.type)
      formData.append("user", this.user.id)
      formData.append("latitude", this.location_pos.coords.latitude)
      formData.append("longitude", this.location_pos.coords.longitude)
      axios.post("https://paviconhse.ubuniworks.com/api/v1/clockin",formData).then((response) => {
          if(response.data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Clock IN Request',
                  text: response.data.message
              })
              localStorage.setItem("timesheet", response.data.timesheet)
              localStorage.setItem("timesheetState", response.data.state)
              localStorage.setItem("timesheetStateData", response.data.state_data)
              this.clockState = response.data.state
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Clock IN Request',
                  text: response.data.message
              })
          }
      })
    },
    clockInOT() {
      let formData = new FormData();
      formData.append("type", this.user.type)
      formData.append("user", this.user.id)
      formData.append("timesheet", localStorage.getItem("timesheet"))
      axios.post("https://paviconhse.ubuniworks.com/api/v1/clockinot",formData).then((response) => {
          if(response.data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Clock IN Overtime Request',
                  text: 'You have clocked IN Overtime successfully'
              })
              localStorage.setItem("timesheet", response.data.timesheet)
              localStorage.setItem("timesheetState", response.data.state)
              localStorage.setItem("timesheetStateData", response.data.state_data)
              this.clockState = response.data.state
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Clock IN Overtime Request',
                  text: response.data.message
              })
          }
      })
    },
    clockOut() {
      let formData = new FormData();
      formData.append("type", this.user.type)
      formData.append("user", this.user.id)
      formData.append("timesheet", localStorage.getItem("timesheet"))
      axios.post("https://paviconhse.ubuniworks.com/api/v1/clockout",formData).then((response) => {
          if(response.data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Clock Out Request',
                  text: 'You have clocked Out successfully'
              })
              localStorage.setItem("timesheet", response.data.timesheet)
              localStorage.setItem("timesheetState", response.data.state)
              localStorage.setItem("timesheetStateData", response.data.state_data)
              this.clockState = response.data.state
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Clock Out Request',
                  text: response.data.message
              })
          }
      })
    },
    clockOutOT() {
      let formData = new FormData();
      formData.append("type", this.user.type)
      formData.append("user", this.user.id)
      formData.append("timesheet", localStorage.getItem("timesheet"))
      axios.post("https://paviconhse.ubuniworks.com/api/v1/clockoutot",formData).then((response) => {
          if(response.data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Clock Out Overtime Request',
                  text: 'You have clocked Out Overtime successfully'
              })
              localStorage.setItem("timesheet", response.data.timesheet)
              localStorage.setItem("timesheetState", response.data.state)
              localStorage.setItem("timesheetStateData", response.data.state_data)
              this.clockState = response.data.state
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Clock Out Overtime Request',
                  text: response.data.message
              })
          }
      })
    }

  },
  mounted () {
    this.fetchNotifications(this.user.id)
    let differDate = Date.now() - this.userDate;
    if (differDate > 7200000) {
      this.logoutClick()
    }
    if (localStorage.getItem('timesheetState') != null) {
      this.clockState = localStorage.getItem('timesheetState')
    }
  },
  created() {
    if(!("geolocation" in navigator)) {
      Swal.fire({
          icon: 'error',
          title: 'Location is not available.'
      })
      return;
    }
    navigator.geolocation.getCurrentPosition(pos => {
      this.location_pos = pos;
    }, err => {
        console.log(err);
      Swal.fire({
          icon: 'error',
          title: 'Location is not available.'
      })
    })

    let installPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      
      installPrompt.userChoice.then(result => {
        if(result.outcome === "accepted") {
          console.log("User Accepted")
        }else {
          console.log("User Denied")
        }
        installPrompt = null
      })
    }

  }


};
</script>
